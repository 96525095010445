<template>
    <div class="powerCouple col-12 py-2 bg_gl" v-if="onMap">
        <div class="row">
            <div class="powerCoupleTitle col-12 px-2 pb-2">
                <h4>{{$t('results.sidebar.power_section.headline')}}</h4>
                <p v-html="$t('results.sidebar.power_section.box.desc')" />
            </div>
            <div class="col-12 mb-2 px-2 powerCoupleBoxDropWrapper">
              <a href="#" @click="this.handleDropdownClick" class="cat01" data-oki="#cat01" :aria-expanded="this.dropdownOpen">
                <!-- <span class='icon icon-case'></span>  -->
                <span class="val">{{this.getDropdownLabel()}}</span> 
                <span class='icon icon-down'></span>
              </a>

              <div class="okiCollapse okiDropdown powerCoupleBoxDrop" :class="{'open': this.dropdownOpen}" id="cat01">
                <div class="row">
                  <div class="col-12">
                    <ul>
                      <li v-for="(elem, idx) in this.categories" :key="'cat_'+idx"><a href="#" @click="this.handleSelectCategory(elem, $event)">{{this.getCategoryName(elem)}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="powerCoupleBox col-12 px-2 pb-2">
                <div class="row">
                    <div class="desc col-12 ta-c pb-1">
                        <p>{{$t('results.sidebar.power_section.choose_category')}}</p>
                    </div>
                    <div class="img col-12">
                        <img :src="require(`@/assets/dist/img/select_pc.jpg`)" :alt="$t('results.sidebar.power_section.headline')">
                    </div>
                </div>
            </div>
            <div class="powerCoupleMore col-12 ta-c px-2">
                <a :href="this.getReadMoreLink()"><span class="val">{{$t('results.sidebar.power_section.more')}}</span> <span class='icon icon-arrow_right'></span></a>
            </div>
        </div>
    </div>
    <section class="powerCouple" v-else>
        <div class="wrap">
            <div class="row">
                <div class="powerCoupleTitle col-12 col-lg-6 px-2 pb-2">
                    <h4>{{$t('results.sidebar.power_section.headline')}}</h4>
                    <p v-html="$t('results.sidebar.power_section.box.desc')" />
                </div>
                <div class="col-12 mb-2 px-2 powerCoupleBoxDropWrapper">
                  <a href="#" @click="this.handleDropdownClick" class="cat01" data-oki="#cat01" :aria-expanded="this.dropdownOpen">
                    <!-- <span class='icon icon-case'></span>  -->
                    <span class="val">{{this.getDropdownLabel()}}</span> 
                    <span class='icon icon-down'></span>
                  </a>

                  <div class="okiCollapse okiDropdown powerCoupleBoxDrop" :class="{'open': this.dropdownOpen}" id="cat01">
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li v-for="(elem, idx) in this.categories" :key="'cat_'+idx"><a href="#" @click="this.handleSelectCategory(elem, $event)">{{this.getCategoryName(elem)}}</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="powerCoupleBox col-12 px-2 pb-2">
                    <div class="row">
                        <div class="desc col-12 ta-c pb-1">
                            <p>Wybierz kategorię biznesu, aby zobaczyć najlepiej pasuje do niej sąsiedztwo</p>
                        </div>
                        <div class="img col-12">
                            <img :src="require(`@/assets/dist/img/select_pc.jpg`)" :alt="$t('results.sidebar.power_section.headline')">
                        </div>
                    </div>
                </div>
                <div class="powerCoupleMore col-12 ta-c px-2">
                    <a :href="this.getReadMoreLink()"><span class="val">{{$t('results.sidebar.power_section.more')}}</span> <span class='icon icon-arrow_right'></span></a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PowerCoupleSection',
    components: {
    },
    props: {
        onMap: {type: Boolean, default: false}
    },
    data() {
      return {
        selectedCategory: null,
        dropdownOpen: false,
        categories: [],
        label: ''
      }
    },
    mounted() {
      this.label = this.getLabel();
      this.$store.dispatch('fetchIndustries').then((data) => {
        this.categories = data;
        // if (data.length > 0) {
        //   this.handleSelectFirstCategory(data[0]);
        // }
      });
    },
    watch: {
      '$store.state.quiz.industry': function() {
        this.label = this.getLabel();
      },
      '$store.state.quiz.lang': function() {
        this.label = this.getLabel();
      }
    },
    methods: {
      handleDropdownClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.dropdownOpen = !this.dropdownOpen;
      },
      handleSelectCategory(category, event) {
        event.preventDefault();
        this.selectedCategory = category;
        this.dropdownOpen = false;
        this.$emit('onCategorySelect', category);
        this.reportCategorySelection();
      },
      getCategoryName(category) {
        var lang = this.$store.state.lang;
        var prefix = lang == 'sk' ? '' : lang == 'en' ? 'En' : 'Uk';
        return category['name'+prefix];
      },
      getDropdownLabel()
      {
        if(this.selectedCategory == null) {
          return this.$t('page.power_section.dropdown_label');
        }
        return this.getCategoryName(this.selectedCategory);
      },
      getBannerSrc() {
        return '@/assets/dist/img/powerCoupleBoxImg05_pl.png';
      },
      getReadMoreLink() {
        return this.$t('results.sidebar.power_section.more_link');
      },
      getLabel() {
        if(this.$store.state.quiz.industry == null) {
          return '';
        }

        var langPrefix = this.$store.state.lang == 'sk' ? '' : this.$store.state.lang == 'en' ? 'En' : 'Uk';
        for(var idx in this.$store.state.industries) {
          if(this.$store.state.quiz.industry == this.$store.state.industries[idx].id) {

            return this.$store.state.industries[idx]['powerCoupleLabel'+langPrefix];
          }
        }

        return '';
      },
      reportCategorySelection()
      {
        var clickInfoObject = {
          "ctaName" : "Select your business category ",
          "ctaType" : "Button",
          "ctaLocation" : "Body",
          "ctaTitle" : "Find complementary businesses to inc. profits",
          "fieldValue1":"category:"+this.getCategoryName(this.selectedCategory)
        };


        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});

      }
    }
}
</script>
