<template>
    <section class="quiz quizMain quizStep04">
      <QuizBg />
      <QuizNav :active="4" />

      <div class="wrap">
        <div class="row jcc">
          <div class="col-12 col-lg-6 p-2">
            <div class="grid">
              <div class="quizTitle ta-c">
                <p class="color_orange"><strong v-if="this.$store.getters.getQuizPathName == 'a'">3/3</strong><strong v-else>4/4</strong></p>
                <h2 class="h4_mob h3-lg">{{$t('quiz.step_4.headline')}}</h2>
              </div>
              <div class="quizForm">
                <form>
                  <div class="row">
                    <div class="rangeSlider col-12" v-bind:class="{'disabled': (this.shouldBudgetCheckboxBeDisable() || this.shouldBuyCheckboxBeDisable())}">
                      <div class="row jcc aic">
                        <div class="col-auto">
                          <a @click="this.decreaseBudget()" class="rangeSliderBtn less"><span class='icon icon-minus'></span></a>
                        </div>
                        <div class="col-auto mx-a">
                          <!-- <label for="range"><span class="val from">{{ this.getRangeStart() }}</span> - <span class="val to">{{this.getRangeEnd()}}</span> <span class="small">zł</span></label> -->
                          <label for="range">{{$t('quiz.step_4.budget_label_to')}} <span class="val to">{{this.getBudget()}}</span> <span class="small">€</span></label>
                        </div>
                        <div class="col-auto">
                          <a @click="this.increaseBudget()" class="rangeSliderBtn more"><span class='icon icon-plus'></span></a>
                        </div>
                        <div class="slider col-12" ref="budgetSlider">
                          <span class="rangeSliderBeforeFirefox"></span>
                          <input v-model="this.budget" type="range" name="range" id="range" v-bind:min="this.min" v-bind:max="this.max" v-bind:step="this.step">
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ta-c">
                      <p>{{$t('quiz.step_4.buy_disclimer')}}</p>
                    </div>
                    <div class="checkbox checkboxW col-12 ta-c" v-bind:class="{'disabled': this.shouldBudgetCheckboxBeDisable()}">
                      <input type="checkbox" name="dnk" id="dnk" value="true" v-model="this.noBudgetYet">
                      <label for="dnk" class="jcc"><span class="val"><strong>{{$t('quiz.step_4.no_budget_yet')}}</strong></span></label>
                    </div>
                    <div class="checkbox checkboxW col-12 ta-c" v-bind:class="{'disabled': this.shouldBuyCheckboxBeDisable()}">
                      <input type="checkbox" name="buy" id="buy" value="true" v-model="this.buy">
                      <label for="buy" class="jcc"><span class="val"><strong>{{$t('quiz.step_4.buy')}}</strong></span></label>
                    </div>
                    <div class="next col-12 col-lg-auto mx-a">
                      <!-- <input type="submit" value="Zobacz wyniki" disabled> -->
                      <router-link :to="{name: 'quiz3', params: {'lang': this.$store.state.lang}}" class="prev"><span class='icon icon-prev'></span> <span class="val">{{$t('quiz.go_back')}}</span></router-link>
                      <router-link @click="this.report()" :to="{name: 'results', params: {'lang': this.$store.state.lang, 'resultsSlug': this.getResultsSlug()}}" class="btn btn-full" v-bind:class="{'disabled': this.isAllowedToGoNext() == false}">{{$t('quiz.see_results')}}</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import QuizBg from "@/views/partials/QuizBg.vue";
import QuizNav from "@/views/partials/QuizNav.vue";

export default {
  name: 'QuizStep4',
  components: {QuizNav, QuizBg},
  data() {
    return {
      budget: null,
      min: 250,
      max: 3000,
      step: 250,
      noBudgetYet: false,
      buy: false
    }
  },
  mounted() {
    if (this.$store.state.quiz.budget != null) {
      this.budget = this.$store.state.quiz.budget;
    } else {
      this.budget = this.max / 2;
    }

    if(this.$store.state.quiz.buy != null) {
      this.buy = this.$store.state.quiz.buy;
    }

    if(this.$store.state.quiz.noBudgetYet != null) {
      this.noBudgetYet = this.$store.state.quiz.noBudgetYet;
    }

  },
  watch: {
    budget: function(val) {
      this.budget = parseInt(val);
      const tempProggess = this.getProgress(
        val,
        this.min,
        this.max
      );
      // let extraWidth = parseInt((100 - tempProggess) / 10);
      // let extraWidth = parseInt((tempProggess * .08));
      // let extraWidth = '24px';
      let extraWidth = (tempProggess * 48 / 100);
      console.log('watch budget', tempProggess, extraWidth);
      this.setCSSProgress('calc(' + tempProggess + '% - ' + extraWidth + 'px)');

      this.$store.commit('setQuizBudget', parseInt(val));
    },
    buy: function(val) {

      this.$store.commit('setQuizBuy', val);
    },
    noBudgetYet: function(val) {

      this.$store.commit('setQuizNoBudgetYet', val);
    }
  },
  methods: {
    increaseBudget() {
      if(this.budget+this.step <= this.max)
        this.budget += this.step;
    },
    decreaseBudget() {
      if(this.budget-this.step >= this.min)
        this.budget -= this.step;
    },
    getBudget() {
      return this.budget;
    },
    getRangeStart() {
      return this.budget-this.step;
    },
    getRangeEnd() {
      return this.budget+this.step;
    },
    isAllowedToGoNext() {
      return true;
    },
    shouldBudgetCheckboxBeDisable() {
      if(this.buy == true)
        return true;

      return false;
    },
    shouldBuyCheckboxBeDisable() {
      if(this.noBudgetYet == true)
        return true;
      return false;
    },
    getProgress(value, min=this.min, max=this.max) {
      return parseInt(((value - min) / (max - min)) * 100);
    },
    setCSSProgress(progress) {
      this.$refs.budgetSlider.style.setProperty("--ProgressPercent", `${progress}`);
      console.log('this.setCSSProgress', progress);
    },
    getResultsSlug() {
      if(this.$store.state.lang == 'sk') {
        return 'vysledky';
      } else {
        return 'results';
      }
    },
    report() {

      var clickInfoObject = {
        "ctaName" : "See results",
        "ctaType" : "Button",
        "ctaLocation" : "Body",
        "ctaTitle" : " Quiz 4 | What is your monthly rental budget for the premises?",
        "selectedRange": this.noBudgetYet == false && this.buy == false ? this.budget : "",
        "selectedOption": this.noBudgetYet ? "I don't have a budget yet" : this.buy ? "I plan to buy a premises" : ""
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    }
  }
}
</script>