<template>



    <section class="quiz quizMain quizStep03">
      <QuizBg />
      <QuizNav :active="3" />


      <div class="wrap">
        <div class="row jcc">
          <div class="col-12 col-lg-6 p-2">
            <div class="grid">
              <div class="quizTitle ta-c">
                <p class="color_orange"><strong>3/3</strong></p>
                <h2 class="h4_mob h3-lg">{{$t('quiz.step_3.headline')}}</h2>
              </div>
              <div class="quizForm">
                <form>
                  <div class="row jcc">
                    <div class="select col-12" :class="{active: this.isOpen}">
                      <!-- <select name="business" id="business" v-model="industry">
                        <option :value="''">{{$t('quiz.step_3.industry')}}</option>
                        <option v-for="(item, idx) in this.industries" :key="'industry_'+idx" :value="item.id">{{this.getIndustryName(item)}}</option>
                      </select> -->
                      <!-- <a href="#" class="cat" data-oki="#cat" :aria-expanded="this.isOpen" @click.prevent="handleExpanderClick()" >
                        <span class="val">{{ this.industryName }}</span>
                        <span class='icon icon-down ml-a'></span>
                      </a> -->
                      <input type="text" name="business" id="business" 
                        v-model="industryName" 
                        :placeholder="$t('quiz.step_3.industry')" 
                        @focus="this.isOpen=true"
                        @keydown="this.handleIndustryKeyPress"
                        autocomplete="off">
                      <a href="#" class="clearInput" v-if="this.industry != '' && this.industry != null"  @click.prevent="this.handleIndustryInputClear()"><span class='icon icon-x'></span></a>
                      <div class="okiCollapse okiDropdown" :class="{open: this.isOpen}" id="cat">
                          <div class="row">
                              <div class="col-12">
                                  <ul>
                                      <li v-for="(item, idx) in this.industriesFilter" :key="'industry_'+idx"
                                          >
                                          <a href="#" @click.prevent="handleChangeCat(item)"
                                            :class="{active: idx == this.industryFocus}"
                                            :data-value="this.getIndustryName(item)"
                                            :data-id="item.id"
                                            ref="singleIndustryFilterLoop" >{{this.getIndustryName(item)}}</a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="info col-12 mt-a ta-c jcc">
                      <p>{{$t('quiz.step_3.not_found')}}</p>
                    </div>
                    <div class="next col-12 col-lg-auto mx-a">
                      <router-link :to="{name: 'quiz2', params: {'lang': this.$store.state.lang}}" class="prev"><span class='icon icon-prev'></span> <span class="val">{{$t('quiz.go_back')}}</span></router-link>
                      <router-link @click="this.report()" :to="{name: 'results', params: {'lang': this.$store.state.lang, 'resultsSlug': this.getResultsSlug()}}" class="btn btn-full" v-bind:class="{'disabled': this.isAllowedToGoNext() == false}">{{$t('quiz.go_next')}}</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


</template>
<script>
  import QuizBg from "@/views/partials/QuizBg.vue";
  import QuizNav from "@/views/partials/QuizNav.vue";

  export default {
    name: 'QuizStep3',
    components: {QuizNav, QuizBg},
    data() {
      return {
        industry: '',
        industryName: '',
        industries: [],
        industriesFilter: [],
        industryFocus: null,
        isOpen: false,
        autocompleteTimeout: null
      }
    },
    watch: {
      industry: function (val) {
        this.$store.commit('setQuizIndustry', val == '' ? null : parseInt(val));
      }
    },
    mounted() {
      this.$store.dispatch('fetchIndustries').then((response) => {
        this.industries = response;
        this.industriesFilter = response;

        console.log('mointedIndustries', this.industries, this.$store.state.quiz.industry);
        if (this.$store.state.quiz.industry) {
          var tempIndustry = this.industries.filter(e => {
            return e.id == this.$store.state.quiz.industry;
          });
          this.industryName = this.getIndustryName(tempIndustry[0]);
        }
      });

      this.industry = this.$store.state.quiz.industry;
      if(this.industry == null)
        this.industry = '';
    },
    methods: {
      getResultsSlug() {
        if(this.$store.state.lang == 'sk') {
          return 'vysledky';
        } else {
          return 'results';
        }
      },
      getIndustryName(item) {
        if(this.$store.state.lang == 'sk')
          return item.name;
        else if(this.$store.state.lang == 'en')
          return item.nameEn;
        else if(this.$store.state.lang == 'uk')
          return item.nameUk;

      },
      isAllowedToGoNext() {
        if(this.industry != '' && this.industry != null)
          return true;

        return false;
      },
      handleExpanderClick() {
        if(this.isOpen) {
          this.isOpen = false;
          // document.body.classList.remove('okiCollapseOpen');
        } else {
          this.isOpen = true;
        }
      },
      handleChangeCat(e) {
        // this.industryName = e.target.dataset.value;
        this.industryName = this.getIndustryName(e);
        this.industry = e.id;
        this.isOpen = false;
        // console.log(e, 'handleChangeCat');
        // this.$emit('changeCat', this.curentCatId);
      },
      handleIndustryInputClear() {
        this.industryName = '';
        this.industry = '';
        this.industriesFilter = this.industries;
        this.isOpen = false;
      },
      handleIndustryKeyPress(e) {
        clearTimeout(this.autocompleteTimeout);
        switch (e.keyCode) {
          case 38:
            // up
            if (this.industryFocus === null) {
              this.industryFocus = 0;
            } else if (this.industryFocus > 0) {
              this.industryFocus--;
            }
            // console.log('up', this.industryFocus);
            break;
          
          case 40:
            // down
            if (this.industryFocus === null) {
              this.industryFocus = 0;
            } else if (this.industryFocus < this.industriesFilter.length - 1) {
              this.industryFocus++;
            }
            // console.log('down', this.industryFocus);
            break;
          
          case 13:
            e.preventDefault();
            // console.log('enter', this.$refs.singleIndustryFilterLoop[this.industryFocus], this.$refs.singleIndustryFilterLoop[this.industryFocus].dataset, this.industriesFilter);

            var tempSelection = this.industries.filter(el => {
              var tempFocusIndustryId = parseInt(this.$refs.singleIndustryFilterLoop[this.industryFocus].dataset.id)
              return el.id == tempFocusIndustryId;
            });
            // console.log('enter tempSelection', tempSelection[0]);
            this.handleChangeCat(tempSelection[0]);
            break;

          default:
            this.industryFocus = null;
            if (this.industryName.length > 0) {
              this.autocompleteTimeout = setTimeout(() => {
                this.industriesFilter = this.industries.filter(entry => {
                  return entry.name.toLowerCase().includes(this.industryName.toLowerCase());
                });
              }, 500);
            }
            break;
        }
      },
      report() {

        var clickInfoObject = {
          "ctaName" : "See results",
          "ctaType" : "Button",
          "ctaLocation" : "Body",
          "ctaTitle" : " Quiz 3 | Which industry are you interested in?",
          "fieldValue1" :"industry:"+ this.industryName,
        };

        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});

      }

    }
  }
</script>