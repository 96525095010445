import { createI18n } from 'vue-i18n'
//import VueI18n from 'vue-i18n'
import axios from 'axios'

var defaultLanguage = 'sk';
var langs = ['sk', 'en', 'uk'];
var navigatorLanguage = navigator.language.toLowerCase();
if(navigatorLanguage.indexOf('-') !== -1)
    navigatorLanguage = navigatorLanguage.substr(0, navigatorLanguage.indexOf('-'));

for(var i in langs) {
    if(navigatorLanguage == langs[i])
        defaultLanguage = langs[i];
}

//we dont care about navigator language
defaultLanguage = 'sk';

function setI18nLanguage (lang) {
    i18n.locale = lang;
    i18n.global.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export const defaultLocale = defaultLanguage

const i18n = createI18n({
    locale: defaultLanguage,
    fallbackLocale: 'sk'
});
export default i18n;


export function loadLanguageAsync (lang) {

    if (langs.includes(lang)) {
         if (i18n.locale !== lang)
             setI18nLanguage(lang)

         //return Promise.resolve()
    } else {
        return setI18nLanguage(defaultLocale);
    }

    // if(process.env.VUE_APP_API_LOGIN != '' && process.env.VUE_APP_API_PASSWORD != '') {
    //     var basicAuth = 'Basic ' + btoa(process.env.VUE_APP_API_LOGIN + ':' + process.env.VUE_APP_API_LOGIN);
    // } else {
    //     basicAuth= '';
    // }


    return axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL+`/lang/${lang}`,
        headers: {
            //'Authorization': basicAuth
        }
    }).then(function(response) {

        let msgs = response.data;

        //loadedLanguages.push(lang)
        //i18n.messages = msgs;
        //setI18nLanguage(i18n.locale);

        i18n.global.setLocaleMessage(i18n.locale, msgs[i18n.locale]);

    })
}

