<template>
    <div class="okiModalText col-12">
        <Carousel 
            ref="modalCarousel"
            v-model="currentModalSlide" 
            v-bind="modalSettings" 
            :transition="500"
            :wrap-around="false"
            @init="handleInit"
            @slide-start="handleSlideStart"
            >

            <Slide v-for="(slide, idx) in this.$props.slidesData" :key="'okiModalTextSliderSlide_' + idx" >
                <div class="row">
                    <div class="okiModalImg okiModalImgFull col-12 px-0">
                        <img :src="slide.image" 
                            :alt="this.getSlideTitle(slide)">
                    </div>
                    <ModalTextSingle class=" p2t-1 px-2"
                        :title="this.getSlideTitle(slide)"
                        :desc="this.getSlideDescription(slide)" />
                </div>
            </Slide>
            
            <template #addons>
                <Pagination />
            </template>
        </Carousel>
    </div>
</template>

<script>

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import ModalTextSingle from '@/components/ModalTextSingle.vue';

export default {
    name: 'OkiModalListSlider',
    expose: ['next', 'prev', 'restartCarousel', 'handleInit'],
    data() {
        return {
            // carousel settings
            currentModalSlide: 0,
            modalSettings: {
                itemsToShow: 1,
                snapAlign: 'end'
            }
            // carousel settings
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        ModalTextSingle
    },
    props: {
        slidesData: {}
    },
    mounted() {
        // console.log('mouted slides  this.$props.slidesData',  this.$props.slidesData, this.$refs.modalCarousel);
    },
    updated() {
        // console.log('updated slides  this.$props.slidesData',  this.$props.slidesData, this.$refs.modalCarousel.data);
        this.$emit('slder-on-upadte', this.$refs.modalCarousel.data);
    },
    methods: {
        getSlideTitle(slide) {
          if(this.$store.state.lang == 'sk')
            return slide.title;
          else if(this.$store.state.lang == 'en')
            return slide.titleEn;
          else if(this.$store.state.lang == 'uk')
            return slide.titleUk;
        },
        getSlideDescription(slide) {
          if(this.$store.state.lang == 'sk')
            return slide.description;
          else if(this.$store.state.lang == 'en')
            return slide.descriptionEn;
          else if(this.$store.state.lang == 'uk')
            return slide.descriptionUk;
        },
        next() {
            this.$refs.modalCarousel.next();
            // this.$refs.modalCarousel.updateSlideWidth();
            this.$refs.modalCarousel.restartCarousel();
            // slder-on-change
        },
        prev() {
            this.$refs.modalCarousel.prev();
        },
        restartCarousel() {
            console.log('triger reset carousel');
            this.$refs.modalCarousel.restartCarousel();
            // this.$refs.modalCarousel.updateSlideWidth();
            // this.$refs.modalCarousel.updateSlidesData();
            // this.$refs.modalCarousel.slideTo(0);
            // this.$refs.modalCarousel.restartCarousel();
        },
        handleInit(dat) {
            console.log('created', dat);

            this.$refs.modalCarousel.slideTo(0);
        },
        handleSlideStart(dat) {
            console.log('slide-start', dat, dat.currentSlideIndex, dat.slidesCount);

            this.$emit('slder-on-change', dat);
            // if (dat.slidingToIndex === (dat.slidesCount - 1)) {
            //     console.log('emit slder-on-change, to detect last slide');
            // }
            // modalCarousel
        },
    }
};
</script>