<template>

    <section class="quiz quizMain quizStep02">
      <QuizBg />
      <QuizNav :active="2" />

      <div class="wrap visible">
        <div class="row jcc">
          <div class="col-12 col-lg-6 p-2">
            <div class="grid">
              <div class="quizTitle ta-c">
                <p class="color_orange"><strong v-if="this.$store.getters.getQuizPathName == 'a'">2/3</strong><strong v-else>2/3</strong></p>
                <h2 class="h4_mob h3-lg">{{$t('quiz.step_2.headline')}}</h2>
              </div>
              <div class="quizForm">
                <form>
                  <div class="row jcc">
                    <div class="town col-12" :class="{'active': this.cityDropdownActive}">
                      <input type="text" name="town" id="town" :placeholder="$t('city')" v-model="this.selectedCityName" @keydown="this.handleCityKeyPress" @focus="this.cityDropdownActive=true" autocomplete="off">
                      <a href="#" class="clearInput" v-if="this.city != '' && this.city != null" @click.prevent="this.handleCityInputClear()"><span class='icon icon-x'></span></a>
                      <div class="okiCollapse okiDropdown" id="townSelect">
                          <div class="row">
                              <div class="col-12">
                                  <ul>
                                    <li v-for="(cityInLoop, idx) in this.citiesFilter"
                                      :key="cityInLoop.id" 
                                      :data-value="cityInLoop.id"
                                      >
                                      <a href="#" @click.prevent="this.handleCitySelect(cityInLoop)" 
                                        :class="{active: idx == this.cityFocus}"
                                        :data-city="cityInLoop.id"
                                        ref="singleCityFilterLoop"
                                        >
                                        {{this.getCityName(cityInLoop)}}
                                      </a>
                                    </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- <select name="town" id="town" v-model="this.city">
                        <option v-bind:value="''">{{$t('city')}}</option>
                        <option v-for="cityInLoop in this.cities" :key="cityInLoop.id" v-bind:value="cityInLoop.id">{{cityInLoop.name}}</option>
                      </select> -->
                    </div>
                    <div v-if="this.isAddressRequired()" class="street col-12" :class="{'active': this.addressDropdownActive}">
                      <input v-model="this.addressName" @keydown="this.handleAddressKeyPress" type="text" name="street" id="street" v-bind:placeholder="$t('quiz.step_2.street')" autocomplete="off">
                      <a v-if="false" @click="this.findMyLocation()" class="allowLoc"><span class='icon icon-loc2'></span></a>
                      <a href="#" class="clearInput" v-if="this.address != '' && this.address != null" @click.prevent="this.handleAddressInputClear()"><span class='icon icon-x'></span></a>
                      <div class="okiCollapse okiDropdown" id="addressSelect">
                        <div class="row">
                          <div class="col-12">
                            <ul>
                              <li v-for="addressInLoop in this.addresses" :key="addressInLoop.id" :data-value="addressInLoop.id">
                                <a href="#" @click.prevent="this.handleAddressSelect(addressInLoop)">{{addressInLoop.text}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <!-- v-if="this.isGeolocationAvailable()" -->
                    </div>
                    <div v-if="this.geolocationError" class="col-12 pl-2">
                      <div class="alert alert-danger" role="alert">{{$t('geolocation_error')}}</div>
                    </div>
                    <div class="next col-12 col-lg-auto mx-a">
                      <!-- <input type="submit" value="Dalej" disabled> -->
                      <router-link :to="{name: 'quiz1', params: {'lang': this.$store.state.lang}}" class="prev"><span class='icon icon-prev'></span> <span class="val">{{$t('quiz.go_back')}}</span></router-link>
                      <router-link @click="this.report()" :to="{name: 'quiz3', params: {'lang': this.$store.state.lang}}" class="btn btn-full" v-bind:class="{'disabled': this.isAllowedToGoNext() == false}">{{$t('quiz.go_next')}}</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import QuizBg from "@/views/partials/QuizBg.vue";
import QuizNav from "@/views/partials/QuizNav.vue";

export default {
  name: 'QuizStep2',
  components: {QuizNav, QuizBg},
  data() {
    return {
      city: "",
      selectedCityName: "",
      address: null,
      addressName: "",
      coords: null,
      geolocationError: false,
      cities: [],
      citiesFilter: [],
      cityFocus: null,
      cityDropdownActive: false,
      addressDropdownActive: false,
      autocompleteTimeout: null,
      addresses: []
    }
  },
  mounted() {
    this.$store.dispatch('fetchCities').then((response) => {
      this.cities = response;
      this.citiesFilter = response;
    });
    if(this.$store.state.quiz.city != null) {
      this.city = this.$store.state.quiz.city;
      this.citiesFilter = [this.$store.state.quiz.city];
      this.selectedCityName = this.getCityName(this.$store.state.quiz.city);
    }

    //this.city = this.$store.state.quiz.city;
    this.address = this.$store.state.quiz.address;
    if(this.$store.state.quiz.address != null)
      this.addressName = this.$store.state.quiz.address.text;
  },
  watch: {
    city: function(newVal) {
      this.$store.commit('setQuizCity', newVal);
    },
    address: function(newVal) {
      this.$store.commit('setQuizAddress', newVal);
    }
  },
  methods: {
    getCityName(city) {
      if(this.$store.state.lang == 'en')
        return city.nameEn;
      else if(this.$store.state.lang == 'uk')
        return city.nameUk;

      return city.name;
    },
    isAddressRequired() {
      if(this.$store.state.quiz.question_1 == 1 || this.$store.state.quiz.question_1 == 4)
        return true;

      return false;
    },
    isAllowedToGoNext() {
      if(this.city != "" && (this.address != null || !this.isAddressRequired()))
        return true;

      return false;
    },
    findMyLocation() {
      var thisComponent = this;
      window.navigator.geolocation.getCurrentPosition(function(position) {

        thisComponent.coords = {
          'lat': position.coords.latitude,
          'lng': position.coords.longitude
        };

        thisComponent.getAddressByCoords();

      }, function() {
          thisComponent.geolocationError = true;
      });
    },
    isGeolocationAvailable() {
      return window.navigator.geolocation;
    },
    getAddressByCoords() {
      this.$store.dispatch('reverseGeolocation', this.coords).then((response) => {
        this.address = response.address;
      }).catch(() => {
        this.geolocationError = true;
      });
    },
    handleCityKeyPress(e) {
      clearTimeout(this.autocompleteTimeout);
      switch (e.keyCode) {
        case 38:
          // up
          if (this.cityFocus === null) {
            this.cityFocus = 0;
          } else if (this.cityFocus > 0) {
            this.cityFocus--;
          }
          break;
        
        case 40:
          // down
          if (this.cityFocus === null) {
            this.cityFocus = 0;
          } else if (this.cityFocus < this.citiesFilter.length - 1) {
            this.cityFocus++;
          }
          break;
        
        case 13:
          e.preventDefault();
          // console.log('enter', this.$refs.singleCityFilterLoop[this.cityFocus], this.$refs.singleCityFilterLoop[this.cityFocus].dataset, this.citiesFilter);
          var tempSelection = this.cities.filter(el => {
            var tempFocusCityId = parseInt(this.$refs.singleCityFilterLoop[this.cityFocus].dataset.city)

            return el.id == tempFocusCityId;
          });
          // this.selectedCityName = tempSelection[0].name;
          // this.city = tempSelection;
          // this.cityDropdownActive = false;
          this.handleCitySelect(tempSelection[0]);
          break;

        default:
          this.cityFocus = null;
          this.autocompleteTimeout = setTimeout(() => {
            // console.log('handleCityKeyPress', This, e, this.cities, this.selectedCityName);
            this.citiesFilter = this.cities.filter(entry => {
              return entry.name.toLowerCase().includes(this.selectedCityName.toLowerCase());
            });
          }, 500);
          break;
      }
      // console.log('handleCityKeyPress endswitch', e.keyCode, this.cityFocus, this.selectedCityName);
    },
    handleCitySelect(city) {
      this.city = city;
      this.selectedCityName = this.getCityName(city);
      this.cityDropdownActive = false;
      this.handleAddressInputClear();
    },
    handleCityInputClear() {
      this.city = null;
      this.selectedCityName = "";
      this.cityDropdownActive = false;
      this.citiesFilter = this.cities;
    },
    handleAddressInputClear() {
      this.address = null;
      this.addressName = "";
      this.addressDropdownActive = false;
    },
    handleAddressKeyPress() {
      if(this.addressName.length < 2) {
        return;
      }
      // console.log('handleAddressKeyPress', e.target.value.length, this.addressName);
      clearTimeout(this.autocompleteTimeout);
      var This = this;
      this.autocompleteTimeout = setTimeout(() => {

        this.$store.dispatch('fetchAutocompleteAddress', {
          'address': This.selectedCityName+' '+This.addressName,
          'lat': this.city.lat,
          'lng': this.city.lng

        }).then((response) => {
          This.addresses = [];
          for(var idx in response.features) {
            if(response.features[idx].place_type.indexOf('address') !== false) {

              for(var idx2 in response.features[idx].context) {
                if(response.features[idx].context[idx2].id.indexOf('municipality') !== -1) {

                  var cityNames = [This.$store.state.quiz.city.name, This.$store.state.quiz.city.nameEn, This.$store.state.quiz.city.nameUk];
                  if(cityNames.indexOf(response.features[idx].context[idx2].text_sk) !== -1 || cityNames.indexOf(response.features[idx].context[idx2]['text_'+This.$store.state.lang]) !== -1) {
                    This.addresses.push(response.features[idx]);
                  }

                }
              }
            }
          }
          if (This.addresses.length > 0) {
            This.addressDropdownActive = true;
          }
        });
      }, 500);
    },
    handleAddressSelect(address) {

      this.address = address;
      this.addressName = address.text;
      this.addressDropdownActive = false;
    },
    report() {

      var clickInfoObject = {
        "ctaName" : "Next",
        "ctaType" : "Button",
        "ctaLocation" : "Body",
        "ctaTitle" : " Quiz 2 | Which city are you interested in?",
        "fieldValue1": "city:"+this.selectedCityName, // selected city name,
        "fieldValue2": "street:["+this.addressName+"]" // selected street name
      };
      if(this.addressName == null || this.addressName == '') {
        delete clickInfoObject.fieldValue2;
      }



      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    }
  }
}
</script>