<template>
  <div class="map-wrap" style="width: 100%; height: 100%; position: absolute;">
    <div id="map" class="map" ref="mapContainer" style="height: 100%;"></div>

  </div>
</template>

<style>
.ol-zoom {
  right: 0.5em !important;
  left: auto !important;
}
@media (max-width: 767px) {
  .ol-zoom {
    right: 0.5em !important;
    left: auto !important;
    top: calc(50% - 23px) !important;
  }
}
</style>
<script>

import 'ol/ol.css';
//import { apply } from 'ol-mapbox-style';
import Map from 'ol/Map';
import {easeIn} from 'ol/easing';
import View from 'ol/View';
import {Select, defaults as defaultsInteraction} from "ol/interaction";
import {getCenter} from "ol/extent";
//import Click from "ol/events/condition";
import {Attribution} from "ol/control";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {defaults} from "ol/control";
import {fromLonLat, transform} from "ol/proj";
import {GeoJSON} from "ol/format";
import {Style, Fill, Stroke, Icon} from "ol/style";
import {Point} from "ol/geom";
import {Feature} from "ol";
import TileJSON from "ol/source/TileJSON";
import TileLayer from "ol/layer/Tile";



const key = process.env.VUE_APP_MAPTILER_API_KEY;
const attribution = new Attribution({
  collapsible: false,
});
var projection = 'EPSG:3857';
//const styleJson = `https://api.maptiler.com/maps/8f8ac384-8703-4579-8736-eeb84a53bea7/style.json?key=${key}`;
var map = null;
var selectedSquareId = null;



var colors = [
  'rgba(255, 188, 31, 0.12)',
  'rgba(255, 166, 30, 0.24)',
  'rgba(255, 146, 29, 0.36)',
  'rgba(255, 127, 28, 0.48)',
  'rgba(255, 103, 27, 0.6)',
];

var selected = new Style({
  fill: new Fill({
    color: '#eeeeee',
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 255, 0.7)',
    width: 2,
  }),
});

const selectSingleClick = function(mapWidget) {

  return new Select({
    condition:function(event){
      return event.type == 'singleclick';
    },
    style: mapWidget.selectStyle,
    filter: function(feature){
      return 'values_' in feature && 'id' in feature.values_
    }//filtering speeds up the process
  });
}

var selectInteraction = null;

// Symuluj zdarzenie kliknięcia w punkt na mapie (x, y)
function simulateClickEvent(x, y) {

  // Pobierz obiekt z mapy w danym punkcie
  var features = map.getFeaturesAtPixel([x, y], {
    layerFilter: function(layer) {
      return layer.values_.name === 'grid-layer'; // Filtrowanie warstw, jeśli potrzebne
    }
  });

  if (features.length > 0) {

    // Znaleziono obiekty - wywołaj zdarzenie wyboru
    selectInteraction.getFeatures().clear(); // Wyczyść poprzednie zaznaczenie
    selectInteraction.getFeatures().extend(features); // Zaznacz znalezione obiekty
    // Tutaj możesz dodać obsługę zdarzenia zaznaczenia, jeśli chcesz
  } else {
    // Brak obiektów - wyczyść zaznaczenie
    selectInteraction.getFeatures().clear();
  }
}



const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 40],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: require('@/assets/dist/img/pin_g.png'),
  }),
  zIndex: 10000,
});

const iconStyleRed = new Style({
  image: new Icon({
    anchor: [0.5, 40],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: require('@/assets/dist/img/pin.png'),
  }),
  zIndex: 10000,
});


var source;
var layer;
var realEstateLayer;
var mapLoadedHandlerInterval = false;
//var featureSelectedAtStart = null;

const sourceTileJSON = new TileJSON({
  url: `https://api.maptiler.com/maps/8f3d8157-decd-41d9-a9df-9d02a946af35/tiles.json?key=${key}`, // source URL
  tileSize: 512,
  crossOrigin: 'anonymous'
});

var MapWidget = {
  name: 'MapWidget',
  props: {
    pinsShouldBeShown: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mapLoaded: false,
      features: [],
      featuresStylesIndexes: [],
      //featureSelectedAtStart: null,
      geoJson: null,
      city: null,
      address: null,
      selectedFeature: null,
      scoring: [],
      squaresLoaded: false,
      offersLoaded: false,
      styles: [
        {
          fill: new Fill({
            color: colors[0]
          })
        },
        {
          fill: new Fill({
            color: colors[1]
          })
        },
        {
          fill: new Fill({
            color: colors[2]
          })
        },
        {
          fill: new Fill({
            color: colors[3]
          })
        },
        {
          fill: new Fill({
            color: colors[4]
          })
        },
      ],
      stylesObjects: [],
      needToReselect: false,
    }
  },
  mounted() {
    this.initWithMapReset();
  },
  watch: {
    pinsShouldBeShown: function (val) {
      if(val) {
        map.addLayer(realEstateLayer);
      } else {
        map.removeLayer(realEstateLayer);
      }
    },
    'this.$store.state.quiz.industry': function () {
      //this.loadSquares();
    }
  },
  methods: {
    initWithMapReset() {
      map = null;
      this.init();
    },
    init() {
      selectedSquareId = null;
      this.stylesObjects = [];

      for(var idx in this.styles) {
        this.stylesObjects.push(new Style(this.styles[idx]));
      }

      var This = this;
      //setting up city selected in quiz
      if(this.$store.state.quiz.city != null) {
        this.city = this.$store.state.quiz.city;
      }

      this.address = this.$store.state.quiz.address;

      var interactions = defaultsInteraction({altShiftDragRotate:false, pinchRotate:false});
      if(map == null) {
        map = new Map({
          layers: [
            new TileLayer({
              source: sourceTileJSON
            }),
          ],
          target: 'map',
          controls: defaults({attribution: false}).extend([attribution]),
          view: new View({
            constrainResolution: true,
            zoom: 12,
            minZoom: 11,
            maxZoom: 18,
            center: transform([17.108341,48.143806], 'EPSG:4326', projection),

          }),
          interactions: interactions,
        });

        map.on('singleclick', function (evt) {

          var markerHasBeenClicked = false;
          map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            if (layer.values_.name === 'real-estates') {
              markerHasBeenClicked = true;
            }
          });
          if (markerHasBeenClicked == false) {
            map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {

              if (layer.values_.name !== 'grid-layer') return;
              if (feature) {

                map.getView().animate({
                  center: evt.coordinate,
                  duration: 500,
                  zoom: This.isMobile() ? 14 : 16,
                  easing: easeIn
                }, function () {
                  setTimeout(() => {
                    var center = map.getView().getCenter();
                    map.getView().setCenter([center[0] + 0.000000001, center[1] + 0.000000001]);//fix for refresh map due to display old square selection
                  }, 500);

                });
              }

            });
          } else {
            const coordinate = evt.coordinate;
            var firstItem = null;
            map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {

              if (firstItem != null) return;//only first marker at this point

              if (layer.values_.name !== 'real-estates') return;

              if (feature && 'isOffer' in feature.values_) {
                This.$emit('offer-selected', feature.values_);

                firstItem = feature;
                //map.getView().setCenter(coordinate);
                map.getView().animate({
                  center: coordinate,
                  duration: 500,
                  zoom: This.isMobile() ? 14 : 16,
                  easing: easeIn
                });
              }

            });
            This.reportMarkerClick()
          }


        });

        //obsługa zaznaczenia po kliku
        map.addInteraction(selectSingleClick(this));
        //apply(map, styleJson);

        //obsługa manualnego zaznaczenia bez kliku przy załadowaniu mapy
        selectInteraction = map.getInteractions().getArray().find(function (interaction) {
          return interaction instanceof Select;
        });
      }

      if(this.city != null) {
        if(this.address == null) {//if address is not set, center map to city center
          this.showCity();
        }
        this.loadSquares();
      }

      if(this.address != null) {

        map.on('loadend', function() {

          This.onMapLoadHandler();
        });


        map.getView().setCenter(transform([this.address.center[0], this.address.center[1]], 'EPSG:4326', projection));
        map.getView().setZoom(This.isMobile() ? 14 : 16);

        mapLoadedHandlerInterval = setInterval(function(MapWidget) {
          MapWidget.onMapLoadHandler();
        }, 1000, This);
      }

      map.on('pointermove', function(e){
        var pixel = map.getEventPixel(e.originalEvent);
        var hit = map.hasFeatureAtPixel(pixel);
        map.getViewport().style.cursor = hit ? 'pointer' : '';
      });

    },
    setNeedToReselect(val) {
      this.needToReselect = val;
    },
    onMapLoadHandler() {
      //loadend
      var This = this;

      if(This.mapLoaded == true && This.squaresLoaded == true) return;

      var mapSize = map.getSize();

      //TODO need to set selection for square if new category was loaded
      selectedSquareId;

      map.forEachFeatureAtPixel([mapSize[0]/2, mapSize[1]/2], function(feature, layer) {
        if(layer.values_.name !== 'grid-layer') return;
        if (feature) {

          clearInterval(mapLoadedHandlerInterval);
          var featureExtent = feature.getGeometry().getExtent();
          var featureCenter = getCenter(featureExtent);
          map.getView().setCenter([featureCenter[0], featureCenter[1]]);//centering to center of selected square


          selectedSquareId = feature.values_.id;
          //This.selectStyle(feature);//this shows real estate offers
          This.$emit('square-selected', feature.values_);
          //symulowanie kliku po załadowaniu się mapy
          simulateClickEvent(mapSize[0]/2, mapSize[1]/2);

          This.mapLoaded = true;

        }
      });


    },
    getScoring(categoryId) {
      return this.$store.dispatch('fetchScoring', {
        cityId: this.$store.state.quiz.city.id,
        categoryId: categoryId,
      }).then((response) => {
        this.scoring = response;
      });
    },
    getRealEstates(squareId) {

      this.$store.dispatch('fetchSquareOffers', {
          'squareId': squareId,
          'budget': this.$store.state.quiz.budget,
          'noBudgetYet': this.$store.state.quiz.noBudgetYet,
          'buy': this.$store.state.quiz.buy,
        }).then((response) => {
          //need to send offers to sidebar via results view
          this.$emit('offersLoaded', response);


          map.removeLayer(realEstateLayer);
          var featrueList = [];
          for(var idx in response) {


            var elem = new Feature({
              isOffer: true,
              geometry: new Point(fromLonLat([response[idx].lng, response[idx].lat])),
              offer: response[idx]
            });

            elem.setStyle(this.getMarkerIconStyle(response[idx]));
            featrueList.push(elem);
          }

          realEstateLayer = new VectorLayer({
            name: 'real-estates',
            source: new VectorSource({
              features: featrueList
            }),
            zIndex: 10000,
          });

          if(this.$props['pinsShouldBeShown'])
            map.addLayer(realEstateLayer);

          this.offersLoaded = true;

          var zIndex = 1;
          map.getLayers().forEach(function(layer) {
            layer.setZIndex(zIndex);
          });
          realEstateLayer.setZIndex(10001);

      });
    },
    getMarkerIconStyle(offer) {

      if(this.$store.state.quiz.noBudgetYet == false && this.$store.state.quiz.buy == false) {


        if(offer.price != null && offer.price <= this.$store.state.quiz.budget) {
          return iconStyleRed;
        } else {
          return iconStyle;
        }
      }

      return iconStyle;
    },
    selectStyle(feature) {

      selected.getFill().setColor(this.featuresStylesIndexes[feature.values_.externalId] == undefined ? colors[0] : colors[this.featuresStylesIndexes[feature.values_.externalId]])
      //selected.getFill().setColor('rgba(255, 103, 27, 0.5)');
      selected.getStroke()
          .setColor('rgba(255, 103, 27, 1)');
      selected.getStroke()
          .setWidth(2);

      //this.$emit('squareSelected', feature.values_);
      this.$emit('square-selected', feature.values_);

      selectedSquareId = feature.values_.id;
      this.getRealEstates(feature.values_.id);

      return selected;
    },
    showCity() {
      this.$store.dispatch('fetchCity', this.city.id).then((response) => {
        map.getView().setZoom(12);
        map.getView().setCenter(transform([response.lng, response.lat], 'EPSG:4326', projection));

      });
    },
    loadSquares() {
      var This = this;

      var industryId = this.$store.state.quiz.industry == null ? 0 : this.$store.state.quiz.industry;

      this.getScoring(industryId).then(() => {

          This.$store.dispatch('fetchSquares', {'cityId': This.city.id}).then((response) => {
            map.removeLayer(layer);

            This.geoJson = response;

            source = new VectorSource({
              features: new GeoJSON().readFeatures(This.geoJson),
            });

            layer = new VectorLayer({
              name: 'grid-layer',
              renderMode: 'image',
              source: source,
              maxZoom: 18,
              style: function (feature) {

                if(This.featuresStylesIndexes[feature.values_.id] != undefined) {

                  if(selectedSquareId != null) {
                    if(feature.values_.id == selectedSquareId) {
                      return This.selectStyle(feature);
                    }
                  }

                  return This.stylesObjects[This.featuresStylesIndexes[feature.values_.id]];
                  //return new Style(This.styles[This.featuresStylesIndexes[feature.values_.id]]);
                } else {

                  var idx = This.scoring[feature.values_.externalId+""] - 1;//This.getSquareStyleIndex();

                  This.featuresStylesIndexes[feature.values_.externalId] = idx;

                  if(selectedSquareId != null) {
                    if(feature.values_.id == selectedSquareId) {
                      return This.selectStyle(feature);
                    }
                  }

                  return This.stylesObjects[idx];// new Style(This.styles[idx]);
                }

              }
            });

            layer.setZIndex(100);


            map.addLayer(layer);
            This.squaresLoaded = true;
            This.onMapLoadHandler();




      });





      });


    },
    selectSquare() {
      var This = this;
      var mapSize = map.getSize();
      map.forEachFeatureAtPixel([mapSize[0]/2, mapSize[1]/2], function(feature, layer) {
        if(layer.values_.name !== 'grid-layer') return;
        if (feature) {

          clearInterval(mapLoadedHandlerInterval);
          var featureExtent = feature.getGeometry().getExtent();
          var featureCenter = getCenter(featureExtent);
          map.getView().setCenter([featureCenter[0], featureCenter[1]]);//centering to center of selected square


          selectedSquareId = feature.values_.id;
          //This.selectStyle(feature);//this shows real estate offers
          This.$emit('square-selected', feature.values_);
          //symulowanie kliku po załadowaniu się mapy
          simulateClickEvent(mapSize[0]/2, mapSize[1]/2);

          This.mapLoaded = true;

        }
      });
    },
    getSquareStyleIndex() {
      return Math.floor(Math.random() * colors.length);
    },
    reportMarkerClick() {

      var clickInfoObject = {
        "ctaName" : " Place_Icon",
        "ctaType" : "Icon",
        "ctaLocation" : "Map",
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  }
};
export default MapWidget;
</script>