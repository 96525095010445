<template>
    <section class="about">
        <div class="wrap">
            <div class="row jcc">
                <div class="aboutTitle col-12 col-lg-8 px-2">
                    <h2 class="h3">
                        <span class="icon">
                            <img src="@/assets/dist/img/aboutIcon01.svg" :alt="this.getTitle()">
                        </span>
                        <span class="val">{{this.getTitle()}}</span>
                    </h2>
                    <div class="pt-1">
                        <p v-html="this.getDescription()" />
                        <a :href="$t('home_get_more_info_link')" @click="this.report()" class="more"><span class="val">{{ $t('get_more_info')}}</span> <span class='icon icon-arrow_right'></span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import AboutList from '../partials/AboutList.vue';

export default {
    name: 'AboutSection',
    components: {
        // AboutList,
    },
    mounted() {
        // this.aboutMainLimited = this.aboutMainLimited.splice(0,3);
    },
    data() {
        return {
            aboutMainLimited: this.aboutMain
        }
    },
    props: {
        aboutMain: {type: Array}
    },
    methods: {
      getTitle() {
        if (('home' in this.$store.state.pages) == false)
          return '';
        var page = this.$store.state.pages['home'];
        var langPrefix = this.$store.state.lang == 'sk' ? '' : (this.$store.state.lang == 'en' ? 'En' : 'Uk');

        return page['title'+langPrefix];
      },
      getDescription() {
        if (('home' in this.$store.state.pages) == false)
          return '';
        var page = this.$store.state.pages['home'];
        var langPrefix = this.$store.state.lang == 'sk' ? '' : (this.$store.state.lang == 'en' ? 'En' : 'Uk');

        return page['content'+langPrefix];
      },
      report() {
        var clickInfoObject = {
          "ctaName" : "More information",
          "ctaType" : "Button",
          "ctaLocation" : "Body",
          "ctaTitle" : "About the tool"
        };

        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});
      }
    }
}
</script>
