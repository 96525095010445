<template>
    <div class="mapTop" :class="{scrolled: scrolledMapTopRef, visible: (this.isOpen || this.isOpenCity), overflow: this.overflowMapTopRef}">
        <div class="row nowrap" ref="mapTopRef">
            <div class="col-auto">
              <a href="#" class="loc" data-oki="#city" :aria-expanded="this.isOpenCity" @click.prevent="handleExpanderClickCity()" >
                <span class='icon icon-pin2'></span>
                <span class="val">{{ this.getCityName() }}</span>
                <span class='icon icon-down'></span>
              </a>
              <div class="okiCollapse okiDropdown" :class="{open: this.isOpenCity}" :style="{'left': 0}" id="city">
                <div class="row">
                  <div class="col-12">
                    <ul>
                      <li v-for="(item, idx) in this.cities" :key="'city_'+idx"
                      >
                        <a href="#"
                           @click.prevent="handleChangeCity"
                           :data-id="item.id" >{{this.getCityNameByItem(item)}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

<!--                <a class="loc"><span class='icon icon-pin2'></span> <span class="val" v-html="this.getCityName()" /></a>-->
            </div>
            <div class="col-auto">
                <a href="#" class="cat" data-oki="#cat" :aria-expanded="this.isOpen" @click.prevent="handleExpanderClick()" >
                    <span class='icon icon-case'></span> 
                    <span class="val">{{ this.getCurrentCat() }}</span>
                    <span class='icon icon-down'></span>
                </a>
                <div class="okiCollapse okiDropdown" :class="{open: this.isOpen}" id="cat">
                    <div class="row">
                        <div class="col-12">
                            <ul>
                                <li v-for="(item, idx) in this.industries" :key="'industry_'+idx"
                                    >
                                    <a href="#" 
                                        @click.prevent="handleChangeCat"
                                        :data-value="item.name"
                                        :data-id="item.id" >{{this.getIndustryName(item)}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto" v-if="squareInfo && 0">
                <a href="#" class="hide" @click.prevent="handeShowPins">
                    <span class='icon icon-storefront2'></span> 
                    <span class="val">
                        <span v-if="this.showPins">{{$t('hide_offers')}}</span>
                        <span v-else>{{$t('show_offers')}}</span>
                    </span>
                </a>
            </div>
        </div>
        <a href="#" class="scroll" @click.prevent="handleMapTopRowScrollRight"><span class='icon icon-next'></span></a>
    </div>
</template>

<script>
export default {
    name: 'GlobalMapTop',
    props: {
        categories: {type: Array},
        squareInfo: {type: Object}
    },
    data() {
      return {
        scrolledMapTopRef: false,
        overflowMapTopRef: false,
        city: null,
        industries: [],
        cities: [],
        isOpen: false,
        isOpenCity: false,
        showPins: false,
        curentCat: this.$t('category'),
        curentCatId: null
      }
    },
    mounted() {
      if(this.city === null) {
        this.$store.dispatch('fetchCity', this.$store.state.quiz.city.id).then((response) => {
          this.city = response;
        });
      }
      if(this.$store.state.industries.length > 0)
        this.industries = this.$store.state.industries;
      else {
        this.$store.dispatch('fetchIndustries').then((response) => {
          this.industries = response;
        });
      }
      if(this.$store.state.cities.length > 0)
        this.cities = this.$store.state.cities;
      else {
        this.$store.dispatch('fetchCities').then((response) => {
          this.cities = response;
        });
      }

      this.$emit('showPins', this.showPins);

      if (window.innerWidth < 1024) {
        var _t = this;
        window.addEventListener('resize', _t.handleMapTopMobileOverflow);
      }
    },
    unmounted() { 
      var _t = this;
      window.removeEventListener('resize', _t.handleMapTopMobileOverflow);
    },
    updated() {
      if (window.innerWidth < 1024) {
        var _t = this;
        _t.handleMapTopMobileOverflow;
      }
    },
    watch: {
        industries: function(newVal, oldVal) {
            if(oldVal.length == 0 && newVal.length > 0) {
              for(var idx in this.industries) {
                if(this.industries[idx].id == this.$store.state.quiz.industry) {
                  this.curentCat = this.getIndustryName(this.industries[idx]);
                  this.curentCatId = this.industries[idx].id;
                }
              }
            }
        },
        overflowMapTopRef: function() {
          // console.log('watch handleMapTopMobileOverflow', params);
        }
    },
    methods: {
        getCurrentCat() {
          if(this.curentCat == null)
            return this.$t('category');
          for(var idx in this.industries) {
            if(this.industries[idx].id == this.curentCatId) {
              return this.getIndustryName(this.industries[idx]);
            }
          }
          return this.$t('category');
        },
        handleMapTopMobileOverflow() {
          // console.log('handleMapTopMobileOverflow', this.$refs, window.innerWidth);
          if(this.$refs.mapTopRef == null)
            return;
          if (window.innerWidth < 1024 && this.$refs.mapTopRef != null) {
            var mapTopWrapperWidth = this.$refs.mapTopRef.offsetWidth;
            var mapTopChildrenLen = this.$refs.mapTopRef.children.length;
            var mapTopChildrenWidth = 0;
            if (mapTopChildrenLen > 0) {
              mapTopChildrenWidth = Object.values(this.$refs.mapTopRef.children).reduce((total, i) => total + i.offsetWidth, 0) + (8 * (mapTopChildrenLen - 1));
            }
            if (mapTopWrapperWidth < mapTopChildrenWidth) {
              this.overflowMapTopRef = true;
            } else {
              this.overflowMapTopRef = false;
            }
          }
          // console.log('handleMapTopMobileOverflow ww', window.innerWidth, mapTopWrapperWidth, mapTopChildrenWidth, mapTopChildrenLen);
        },
        getIndustryName(item) {
          if(this.$store.state.lang == 'sk')
            return item.name;
          else if(this.$store.state.lang == 'en')
            return item.nameEn;
          else if(this.$store.state.lang == 'uk')
            return item.nameUk;

        },
        getCityNameByItem(item) {
          if(this.$store.state.lang == 'sk')
            return item.name;
          else if(this.$store.state.lang == 'en')
            return item.nameEn;
          else if(this.$store.state.lang == 'uk')
            return item.nameUk;
        },
        getCityName() {
          if(this.city != null) {

            if(this.$store.state.lang == 'sk')
              return this.city.name;
            else if(this.$store.state.lang == 'en')
              return this.city.nameEn;
            else if(this.$store.state.lang == 'uk')
              return this.city.nameUk;

            return this.city.name
          }

          return '';
        },
        handleMapTopRowScrollRight() {
            console.log(this.$refs.mapTopRef.scrollLeft);
            if (this.scrolledMapTopRef) {
                this.$refs.mapTopRef.scrollLeft = 0;
                this.scrolledMapTopRef = false;
            } else {
                this.$refs.mapTopRef.scrollLeft = 150;
                this.scrolledMapTopRef = true;
            }
        },
        handleExpanderClick() {
            if(this.isOpen) {
                this.isOpen = false;
                // document.body.classList.remove('okiCollapseOpen');
            } else {
                this.isOpen = true;
                this.isOpenCity = false;
                // document.body.classList.add('okiCollapseOpen');
            }
        },
        handleExpanderClickCity() {
          if(this.isOpenCity) {
            this.isOpenCity = false;
            // document.body.classList.remove('okiCollapseOpen');
          } else {
            this.isOpenCity = true;
            this.isOpen = false;
            // document.body.classList.add('okiCollapseOpen');
          }
        },
        handleChangeCat(e) {
            this.curentCat = e.target.dataset.value;
            this.curentCatId = e.target.dataset.id;
            this.isOpen = false;
            // console.log(e, 'handleChangeCat');
            this.$emit('changeCat', this.curentCatId);
            this.reportCategory();
        },
        handleChangeCity(e) {
          for(var idx in this.cities) {
            if(this.cities[idx].id == e.target.dataset.id) {
              this.city = this.cities[idx];
            }
          }

          this.isOpenCity = false;
          this.$emit('changeCity', this.city);
        },
        handeShowPins() {
            this.showPins = !this.showPins;
            this.$emit('showPins', this.showPins);
            this.reportToggleOffers();
        },
        reportCategory() {

          var clickInfoObject = {
            "ctaName" : "Category",
            "ctaType" : "Button",
            "ctaLocation" : "header",
            "categoryName": "["+this.curentCat+"]"
          };

          window.adobeDataLayer.push({ "event" : "ctaClick",
            "clickInfo" : clickInfoObject});

        },
        reportToggleOffers() {

          var clickInfoObject = {
            "ctaName" : "Offers",
            "ctaType" : "Button",
            "ctaLocation" : "header",
            "showHide": "["+(this.showPins ? 'On' : 'Off')+"]"
          };

          window.adobeDataLayer.push({ "event" : "ctaClick",
            "clickInfo" : clickInfoObject});

        }
    }
}
</script>