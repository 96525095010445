<template>
    <div class="links col-12 py-2" v-if="onMap">
        <div class="row aic">
            <div class="linksTitle col-12 px-2">
                <div class="row aic">
                    <h2 class="h3_mob">
                        <span class="icon"><img src="@/assets/dist/img/linksIcon01.svg" alt="{{$t('links')}}"></span> 
                        <span class="val">{{$t('links')}}</span>
                    </h2>
                </div>
            </div>

            <div class="linksList col-12 px-2">
                <div class="row jcc aic">
                    <GlobalLink v-for="single in linksList" 
                        :key="single.id" 
                        :href="single.link"
                        :title="this.getLabel(single)"
                        :tooltip="this.getTooltip(single)"
                    ></GlobalLink>
                </div>
            </div>
        </div>
    </div>
    <section class="links" v-else>
        <div class="wrap visible">
            <div class="row">
                <div class="linksTitle col-12 col-lg-4 px-2">
                    <div class="row aic">
                        <h2 class="h3">
                            <span class="icon"><img src="@/assets/dist/img/linksIcon01.svg" alt="{{$t('links')}}"></span> 
                            <span class="val">{{$t('links')}}</span>
                        </h2>
                    </div>
                </div>

                <div class="linksList col-12 col-lg-8 px-2">
                    <div class="row" >
                        <GlobalLink v-for="single in linksList" 
                            :key="single.id" 
                            :href="single.link"
                            :title="this.getLabel(single)"
                            :tooltip="this.getTooltip(single)"
                        ></GlobalLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GlobalLink from "@/components/GlobalLink.vue";
export default {
    name: 'LinksSection',
    components: {
        GlobalLink
    },
    props: {
        linksList: {type: Array},
        onMap: {type: Boolean, default: false}
    },
    methods: {
        getLabel(single) {

            if(this.$store.state.lang == 'en') {
                return single.labelEn;
            } else if(this.$store.state.lang == 'sk') {
              return single.labelSk;
            } else if(this.$store.state.lang == 'uk') {
              return single.labelUk;
            }
        },
        getTooltip(single) {

          if(this.$store.state.lang == 'en') {
            return single.tooltipEn;
          } else if(this.$store.state.lang == 'sk') {
            return single.tooltipSk;
          } else if(this.$store.state.lang == 'uk') {
            return single.tooltipUk;
          }
        }
    }
}
</script>
